import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetEditSepoStepperStatus,
  SetSEPOAssetManagementPlan,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes';
import { LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { LoadSepoAssetManagentPlanBarriers } from '@app-pot/store/actions/lookup-value.action';
import { SepoExtDto } from '@app-com/api/models';

@Component({
  selector: 'app-edit-sepo-asset-fund',
  templateUrl: './edit-sepo-asset-fund.component.html',
  styleUrl: './edit-sepo-asset-fund.component.scss',
})
export class EditSepoAssetFundComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  @Select(LookupValueState.getSepoAssetManagentPlanBarriers) assetManagentPlanBarriers$: Observable<LookupValue[]>;
  @Select(EditSepoState.getSepoDetails) getSepoDetails$: Observable<SepoExtDto>;
  assetManagentPlanBarriers: { id: number; title: string }[];
  nextClickValidation = false;
  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
  ) {
    super();
    this._prepareForm();
    this.getSepoAssetFundsDataFromStore();
  }
  assetManagementForm: FormGroup;
  errors: { [key: string]: string } = {};
  errorsA: { [key: string]: string } = {};
  creditAmountForm: FormGroup;
  today = new Date();
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  currentStep: EditSepoSequence | undefined;
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  private _prepareForm() {
    this.assetManagementForm = this.formBuilder.group({
      hasAssetManagementPlan: [''],
      hasAssetManagementPlanNo: [''],
      assetManagementPlanLastUpdatedAt: [''],
      assetManagementPlanBarrierId: [''],
      assetManagementPlanOther: [''],
    });

    this.creditAmountForm = new FormGroup({
      amountCarryForward: new FormControl(''),
      amountFundingAllocation: new FormControl(''),
      totalFundingAvailable: new FormControl(''),
      amountCredit: new FormControl('', [Validators.required]),
    });
  }

  getSepoAssetFundsDataFromStore() {
    if (this.getSepoDetails$) {
      this.sub.add(
        this.getSepoDetails$.subscribe((storeSepo) => {
          if (storeSepo) {
            this.assetManagementForm?.patchValue({
              hasAssetManagementPlan: storeSepo.hasAssetManagementPlan,
              hasAssetManagementPlanNo:
                storeSepo.hasAssetManagementPlan != undefined && storeSepo.hasAssetManagementPlan == false
                  ? true
                  : false,
              assetManagementPlanLastUpdatedAt: storeSepo.assetManagementPlanLastUpdatedAt,
              assetManagementPlanBarrierId: storeSepo.assetManagementPlanBarrierId,
              assetManagementPlanOther: storeSepo.assetManagementPlanOther,
            });
            this.creditAmountForm?.patchValue({
              amountCarryForward: storeSepo.amountCarryForward,
              amountFundingAllocation: storeSepo.amountFundingAllocation,
              amountCredit: storeSepo.amountCredit ?? '',
              totalFundingAvailable: storeSepo.totalFundingAvailable ?? '',
            });
          }
        }),
      );
    }
  }
  ngOnInit(): void {
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.AssetManagementFunds) {
          console.log(
            'file: edit-sepo-asset-fund.component.ts:45 ~ EditSepoAssetFundComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
        }
      }),
    );

    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.store.dispatch(new SetSEPOAssetManagementPlan(this.GetSepoAssetManagementFunds()));
            this.router.navigate(['edit-sepo/statement-of-expenditures']);
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ContactInfo));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/contact-info');
            this.router.navigate(['edit-sepo/contact-info']);
          }
        }
      }),
    );

    this.store.dispatch(new LoadSepoAssetManagentPlanBarriers());
    this.sub.add(
      this.assetManagentPlanBarriers$.subscribe((assetManagentPlanBarriers) => {
        this.assetManagentPlanBarriers = assetManagentPlanBarriers.map((list) => {
          return {
            id: list.id,
            title: list.title,
          };
        });
      }),
    );

    this.getSepoAssetFundsDataFromStore();
  }

  GetSepoAssetManagementFunds(): Partial<SepoExtDto> {
    const assetManagementFundsData: Partial<{
      hasAssetManagementPlan: boolean;
      assetManagementPlanLastUpdatedAt: string;
      assetManagementPlanBarrierId: number;
      assetManagementPlanOther: string;
      amountCredit: number;
    }> = {};

    if (this.nextClickValidation || this.assetManagementForm.controls['hasAssetManagementPlan'].value)
      assetManagementFundsData.hasAssetManagementPlan = this.assetManagementForm.controls['hasAssetManagementPlan']
        .value
        ? true
        : false;

    if (this.nextClickValidation || this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value)
      assetManagementFundsData.assetManagementPlanLastUpdatedAt =
        this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value ?? '';

    if (this.nextClickValidation || this.assetManagementForm.controls['assetManagementPlanBarrierId'].value)
      assetManagementFundsData.assetManagementPlanBarrierId =
        this.assetManagementForm.controls['assetManagementPlanBarrierId'].value ?? '';

    if (this.nextClickValidation || this.assetManagementForm.controls['assetManagementPlanOther'].value)
      assetManagementFundsData.assetManagementPlanOther =
        this.assetManagementForm.controls['assetManagementPlanOther'].value ?? '';

    if (this.nextClickValidation || this.assetManagementForm.controls['amountCredit'].value)
      assetManagementFundsData.amountCredit = this.creditAmountForm.controls['amountCredit'].value ?? '';

    return assetManagementFundsData;
  }

  validateOnPrevious(): boolean {
    return true;
  }

  validateOnNext(): boolean {
    this.nextClickValidation = true;
    this.checkAssetManagementErrors();
    this.checkCreditAmountErrors();
    if (Object.keys(this.errors).length == 0 && Object.keys(this.errorsA).length == 0) {
      return true;
    } else {
      setTimeout(() => {
        this.jumpToField('gen-info-heading-1');
      }, 200);
      return false;
    }
  }
  get showErrorFieldsCallout(): boolean {
    const baseHasError =
      (Object.keys(this.errors).length > 0 ? true : false) || (Object.keys(this.errorsA).length > 0 ? true : false);
    return baseHasError && this.nextClickValidation;
  }
  checkAssetManagementErrors() {
    Object.keys(this.assetManagementForm.controls).forEach((control) => {
      if (this.getErrorMessage(control)) {
        this.errors[control] = this.getErrorMessage(control);
      } else {
        delete this.errors[control];
      }
    });
  }
  checkCreditAmountErrors() {
    Object.keys(this.creditAmountForm.controls).forEach((control) => {
      if (this.getErrorMessage(control)) {
        this.errorsA[control] = this.getErrorMessage(control);
      } else {
        delete this.errorsA[control];
      }
    });
  }

  get checkAssetManagementErrorCallout() {
    let baseHasError = false;

    if (this.nextClickValidation === true) {
      this.checkAssetManagementErrors();
      baseHasError = Object.keys(this.errors).length > 0 ? true : false;
    }
    return baseHasError;
  }

  getErrorMessage(name: string): string {
    switch (name) {
      case 'hasAssetManagementPlan':
      case 'hasAssetManagementPlanNo':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlan')?.value ?? false) == false &&
          (this.assetManagementForm.get('hasAssetManagementPlanNo')?.value ?? false) == false
        ) {
          return this.res.transform('assetFundPlanYesNoError', this.pageId);
        }
        break;
      case 'amountCredit':
        if (
          this.creditAmountForm.get('amountCredit')?.hasError('required') ||
          (this.creditAmountForm.get('amountCredit')?.value ?? 0) == 0
        ) {
          return this.res.transform('assetFundCreditAmountError', this.pageId);
        }
        break;
      case 'assetManagementPlanLastUpdatedAt':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlan')?.value ?? false) == true &&
          (this.assetManagementForm.get('assetManagementPlanLastUpdatedAt')?.value ?? '') == ''
        ) {
          return this.res.transform('assetFundLastUpdatedAtError', this.pageId);
        }
        break;
      case 'assetManagementPlanBarrierId':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlanNo')?.value ?? false) == true &&
          (this.assetManagementForm.get('assetManagementPlanBarrierId')?.value === '' ||
            this.assetManagementForm.get('assetManagementPlanBarrierId')?.value === null ||
            this.assetManagementForm.get('assetManagementPlanBarrierId')?.value === undefined)
        ) {
          return this.res.transform('assetFundSelectBarrierError', this.pageId);
        }
        break;
      case 'assetManagementPlanOther':
        if (
          this.assetManagementForm.get('assetManagementPlanBarrierId')?.value == 7 &&
          (this.assetManagementForm.get('assetManagementPlanOther')?.value ?? '') == '' &&
          (this.assetManagementForm.get('assetManagementPlanOther')?.value ?? '').trim().length == 0
        ) {
          return this.res.transform('assetFundBarrierOtherError', this.pageId);
        }
        break;
      default:
        return '';
    }
    return '';
  }
  onFocusOut(controlName: string) {
    if (this.getErrorMessage(controlName)) {
      this.errors[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errors[controlName];
    }
  }
  onFocusIn(controlName: string) {
    delete this.errors[controlName];
  }
  onFocusOutA(controlName: string) {
    if (this.getErrorMessage(controlName)) {
      this.errorsA[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errorsA[controlName];
    }
  }
  onFocusInA(controlName: string) {
    delete this.errorsA[controlName];
  }

  get getAssetManagementPlanYes() {
    return this.assetManagementForm.get('hasAssetManagementPlan')?.value;
  }
  get getAssetManagementPlanNo() {
    return this.assetManagementForm.get('hasAssetManagementPlanNo')?.value;
  }
  get showassetManagementPlanOtherSpecify() {
    return this.assetManagementForm.get('assetManagementPlanBarrierId')?.value === 7;
  }
  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
        if (checkedValue !== undefined) {
          elem.setAttribute('checked', checked ? 'true' : 'false');
        }
      }
    });
  }
  public hasAssetManagementPlanClicked(answer: boolean) {
    if (answer === true) {
      this.assetManagementForm.controls['hasAssetManagementPlanNo'].setValue(false);
      this.setRadioCheckedState('questionNo', false);
      this.assetManagementForm.controls['hasAssetManagementPlan'].setValue(true);
      this.setRadioCheckedState('hasAssetManagementPlan', true);
      this.assetManagementForm.get('assetManagementPlanBarrierId')?.reset();
    } else {
      this.assetManagementForm.controls['hasAssetManagementPlan'].setValue(false);
      this.setRadioCheckedState('hasAssetManagementPlan', false);
      this.assetManagementForm.controls['hasAssetManagementPlanNo'].setValue(true);
      this.setRadioCheckedState('questionNo', true);
      this.assetManagementForm.get('assetManagementPlanLastUpdatedAt')?.reset();
    }

    Object.keys(this.assetManagementForm.controls).forEach((control) => {
      delete this.errors[control];
    });
  }
  onSelectedBarrierValueChange() {
    delete this.errors['hasAssetManagementPlan'];
    delete this.errors['assetManagementPlanBarrierId'];
  }
}
