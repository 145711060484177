import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  SepoCertifiedByRole,
  SepoExtDto,
  SepoFilter,
  SepoProjectExpenditureDto,
  SepoProjectOutcomeDto,
  SepoStatusType,
} from '@app-com/api/models';
import { SepoExtService } from '@app-com/api/services';
import { LgffGoADisplayedColumnDef } from '@app-com/components/lgff-goa-table/lgff-goa-table.component';
import { SetCurrentSEPOId } from '@app-pot/store/actions/current-context.action';
import { ResetSEPOState } from '@app-pot/store/actions/edit-sepo.action';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-sepo',
  templateUrl: './view-sepo.component.html',
  styleUrl: './view-sepo.component.scss',
})
export class ViewSepoComponent {
  pageId = 'SEPO';

  organizationId: number = 0;
  searchFormCtrl: FormControl;
  colDef: LgffGoADisplayedColumnDef[] = [];

  //TODO: Wait for SepoListDto in foundation to be created
  tableData: SepoExtDto[] = [];
  currentPage = 1;
  perPageCount = 25;
  searchTerm = '';
  sub = new Subscription();

  @ViewChild('sepoStatusColTemplate', { static: true })
  sepoStatusColTemplate: TemplateRef<SepoExtDtoVM> | null = null;

  @ViewChild('sepoCurrencyColTemplate', { static: true })
  sepoCurrencyColTemplate: TemplateRef<SepoExtDtoVM> | null = null;

  @ViewChild('actionsColTemplate', { static: true })
  actionsColTemplate: TemplateRef<SepoExtDtoVM> | null = null;

  constructor(
    private sepoService: SepoExtService,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchFormCtrl = new FormControl('');
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.loadData();
    this.initColDef();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  configQuery() {
    //TODO: Searching and sorting will be implemented when foundation API is fully implemented.
    return { organizationId: this.organizationId, body: {} as SepoFilter };
  }

  loadData() {
    this.sub.add(
      this.sepoService.findAll(this.configQuery()).subscribe({
        next: (result) => {
          if (result && result.length > 0) {
            result.forEach((sepo) => {
              this.tableData.push(new SepoExtDtoVM(sepo));
            });
          }
        },
        error: (error: Error) => {
          console.error(
            'file: view-sepo.component.ts:72 ~ ViewSepoComponent ~ this.sepoBatchService.sepoBatchControllerFindAll ~ error:',
            error,
          );
        },
      }),
    );
  }

  getBadgeType(status: SepoStatusType) {
    if (status === SepoStatusType.Certified) return 'success';
    if (status == SepoStatusType.Returned) return 'emergency';
    return 'information';
  }

  initColDef(): void {
    this.colDef = [
      { fieldName: 'programYear', sortable: true, sortName: 'programYear', sortDir: 'desc' },

      {
        fieldName: 'status',
        sortable: true,
        sortName: 'status',
        columnTemplate: this.sepoStatusColTemplate,
      },
      {
        fieldName: 'totalFundingAvailable',
        sortable: true,
        sortName: 'totalFundingAvailable',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'totalLgffExpenditures',
        sortable: true,
        sortName: 'totalLgffExpenditures',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'totalLgffFundingRemaining',
        sortable: true,
        sortName: 'totalLgffFundingRemaining',
        headerClass: 'goa-table-number-header',
        cellClass: 'goa-table-number-column',
        columnTemplate: this.sepoCurrencyColTemplate,
      },
      {
        fieldName: 'actions',
        sortable: false,
        columnTemplate: this.actionsColTemplate,
        cellClass: 'actions-col text-center',
      },
    ];
  }

  applySearchFilter() {
    //TODO: Searching will be implemented when foundation API is fully implemented.
    console.log(
      'file: sepo-table.component.ts:132 ~ SepoTableComponent ~ applySearchFilter ~ this.searchFormCtrl.value:',
      this.searchFormCtrl.value,
    );
  }

  clearSearchFilters(event: Event) {
    //TODO: Searching will be implemented when foundation API is fully implemented.
    console.log('file: sepo-table.component.ts:87 ~ SepoTableComponent ~ event:', event);
  }

  handleSort(event: Event) {
    //TODO: Sorting will be implemented when foundation API is fully implemented.
    console.log('file: sepo-table.component.ts:91 ~ SepoTableComponent ~ handleSort ~ event:', event);
  }

  handlePerPageChange(event: Event) {
    //TODO: Sorting will be implemented when foundation API is fully implemented.
    console.log('file: sepo-table.component.ts:94 ~ SepoTableComponent ~ handlePerPageChange ~ event:', event);
  }

  handlePageChange(event: Event) {
    //TODO: Sorting will be implemented when foundation API is fully implemented.
    console.log('file: sepo-table.component.ts:97 ~ SepoTableComponent ~ handlePageChange ~ event:', event);
  }

  showSEPOEdit(status: string): boolean {
    if (status === SepoStatusType.Draft || status === SepoStatusType.Returned) {
      return true;
    }
    return false;
  }

  onEditSEPO(id: number) {
    this.store.dispatch(new SetCurrentSEPOId(id));
    this.store.dispatch(new ResetSEPOState());
    this.router.navigate(['/edit-sepo'], { state: { sepoId: id } });
  }
}

//TODO: SepoExtDtoVM need to implement SepoListDto
export class SepoExtDtoVM implements SepoExtDto {
  additionalDetails: string = '';
  amountCarryForward: number = 0;
  amountCredit: number = 0;
  amountFundingAllocation: number = 0;
  assetManagementPlanBarrierId: number = 0;
  assetManagementPlanLastUpdatedAt: string = '';
  assetManagementPlanOther: string = '';
  certifiedByRole: SepoCertifiedByRole;
  contactEmailAddress: string = '';
  contactFirstName: string = '';
  contactLastName: string = '';
  contactPhoneNumber: string = '';
  createdAt: string = '';
  createdBy: string = '';
  createdByName: string = '';
  hasAssetManagementPlan: boolean = false;
  id: number = 0;
  idTxt: string = '';
  mediaCommunicationsPlan: boolean = false;
  mediaCommunicationsPlanDetails: string = '';
  organizationId: number = 0;
  programYear: string = '';
  sepoProjectExpenditures: SepoProjectExpenditureDto[] = [];
  sepoProjectOutcomes: SepoProjectOutcomeDto[] = [];
  status: SepoStatusType;
  submittedAt: string = '';
  submittedAtTxt: string = '';
  submittedBy: string = '';
  submittedByName: string = '';
  totalAmountFundingAvailable: number = 0;
  totalAmountFundingAvailableTxt: string = '';
  totalFundingApplied: number = 0;
  totalFundingAppliedTxt: string = '';
  totalFundingAvailable: number = 0;
  totalFundingAvailableTxt: string = '';
  updatedAt: string = '';
  updatedBy: string = '';
  updatedByName: string = '';
  totalLgffExpenditures: number = 0;
  totalLgffFundingRemaining: number = 0;

  constructor(data: SepoExtDto) {
    if (data) {
      Object.assign(this, data);
    }
  }
  totalFutureFundingAvailable: number;
  totalFutureFundingAvailableTxt: string;
}
