<goa-page-block id="edit-sepo-page" width="100%">
  <goa-callout type="information" heading="{{ 'editSepoCalloutHeading' | res: pageId }}" mt="2xl" mb="2xl">
    <ol>
      <li>{{ 'editSepoCalloutStep1' | res: pageId }}</li>
      <li>{{ 'editSepoCalloutStep2' | res: pageId }}</li>
      <li>{{ 'editSepoCalloutStep3' | res: pageId }}</li>
      <li>{{ 'editSepoCalloutStep4' | res: pageId }}</li>
      <li>{{ 'editSepoCalloutStep5' | res: pageId }}</li>
    </ol>

    <div>{{ 'editSepoCalloutFinalLine' | res: pageId }}</div>
  </goa-callout>
  <h2>
    {{ 'editSepoTitle' | res: pageId }}
  </h2>
  <h3>2024-SEPO-0098 - CITY OF EDMONTON (Dummy text)</h3>
  <div class="edit-sepo-deadline-text" *ngIf="currentStep == EditSepoSequence.ContactInfo">
    {{ 'editSepoDeadlineText' | res: pageId }}
  </div>
  <goa-divider mt="xl" mb="xl"></goa-divider>
  <goa-form-stepper
    ml="s"
    mr="s"
    class="non-clickable-stepper"
    data-automation-id="edit-sepo-stepper"
    [step]="currentStep$ | async"
  >
    <goa-form-step
      text="Contact information"
      status="incomplete"
      [status]="(stepperState$ | async)?.[EditSepoSequence.ContactInfo]"
    ></goa-form-step>
    <goa-form-step
      text="Asset management and funds"
      status="incomplete"
      [status]="(stepperState$ | async)?.[EditSepoSequence.AssetManagementFunds]"
    ></goa-form-step>
    <goa-form-step
      text="Statement of expenditures"
      status="incomplete"
      [status]="(stepperState$ | async)?.[EditSepoSequence.StatementExpenditures]"
    ></goa-form-step>
    <goa-form-step
      text="Immediate outcomes"
      status="incomplete"
      [status]="(stepperState$ | async)?.[EditSepoSequence.ImmediateOutcomes]"
    ></goa-form-step>
    <goa-form-step
      text="Certify and submit"
      status="incomplete"
      [status]="(stepperState$ | async)?.[EditSepoSequence.Certification]"
    ></goa-form-step>
  </goa-form-stepper>
  <goa-divider mt="xl" mb="xl"></goa-divider>
  <router-outlet></router-outlet>
  <goa-block direction="column">
    <div id="step-action-buttons" class="flex-container">
      <goa-button
        (click)="onPreviousClicked()"
        type="secondary"
        [ngClass]="{ hiddenButton: currentStep == EditSepoSequence.ContactInfo }"
      >
        {{ 'btnPrevious' | res }}
      </goa-button>
      <goa-button *ngIf="currentStep == EditSepoSequence.Certification" (click)="onSubmitClicked()">
        {{ 'btnSubmit' | res }}
      </goa-button>
      <goa-button *ngIf="currentStep != EditSepoSequence.Certification" (click)="onNextClicked()">
        {{ 'btnSaveContinueNext' | res }}
      </goa-button>
    </div>
    <div id="save-delete-buttons" class="flex-container">
      <goa-button (_click)="isDeleteDraftDialogOpen = true" type="tertiary">{{
        'btnDeleteSepo' | res: pageId
      }}</goa-button>
      <goa-button type="tertiary">{{ 'btnSaveClose' | res }}</goa-button>
    </div>
  </goa-block>

  <app-delete-draft-sepo-data-dialog
    [isOpen]="isDeleteDraftDialogOpen"
    (closeDialog)="onDeleteDraftClicked($event)"
  ></app-delete-draft-sepo-data-dialog>
</goa-page-block>
