interface StringResources {
  [key: string]: { [key: string]: string };
}
export const STRING_RESOURCES: StringResources = {
  COMMON: {
    na: 'Resource Not Available',
    ok: 'OK',
    cancel: 'Cancel',
    open: 'Open',
    close: 'Close',
    add: 'Add',
    itemPerPage: 'Items per page:',
    tabLabelActive: 'Active',
    tabLabelReceived: 'Received',
    tabLabelUnderReview: 'Under Review',
    tabLabelRecommended: 'Recommended',
    tabLabelAll: 'All',
    btnDisplayCol: 'Display',
    btnExport: 'Export',
    searchLabel: 'Search',
    auditHistoryTitle: 'Audit History',
    auditHistoryLowTitle: 'Audit history',
    headerHeading: 'LGFF Program',
    edit: 'Edit',
    delete: 'Delete',
    btnSaveLabel: 'Save',
    btnCancelLabel: 'Cancel',
    pleaseSelectLabel: '-Select year-',
    noResultMsg: 'No results found',
    btnImport: 'Import',
    filterLabel: 'Filter',
    clearFilterLabel: 'Clear filters',
    importAllocSuccessMsg: ' % allocations were successfully imported.',
    importAllocFailureMsg: 'Your import failed. Please try again.',
    errorCalloutHeading: 'The following items on this page require attention:',
    resultsPerPage: 'Result per page',
    addToNewBatchLabel: 'Add to new batch',
    addToExistingBatchLabel: 'Add to existing batch',
    municipalTotalsTitle: 'Municipal Totals',
    emptyWorkNotesMsg: 'There are no work notes at this time.',
    btnBackToApplications: 'Back to applications',
    btnCancelDeleteDraftApp: 'Cancel and delete application',
    btnCancelWithdrawDraftApp: 'Cancel and withdraw application',
    btnSaveClose: 'Save and close',
    btnSaveContinueNext: 'Save and continue to next section',
    btnNext: 'Next',
    btnPrevious: 'Previous',
    btnCancel: 'Cancel',
    btnProceed: 'Proceed',
    btnSubmit: 'Submit',
    yesSubmitBatch: 'Yes, submit batch',
    yesReviewBatch: 'Yes, complete review',
    yesRecommendBatch: 'Yes, recommend batch',
    yesApproveBatch: 'Yes, approve batch',
    noCancel: 'No, cancel',
    submitting: 'Submitting application...',
    true: 'Yes',
    false: 'No',
    LGFF: 'Local Government Fiscal Framework',
    download: 'Download',
    filterBy: 'Filter by',
    clearAllFilters: 'Clear all filters',
    applyFilter: 'Apply filter',
    placeholderAll: '-All-',
    optionsSelected: '%% options selected',
    startReview: 'Start review',
    recommend: 'Recommend',
    approve: 'Approve',
    returnToGrantAdvisor: 'Return to Grant Advisor',
    returnToLocalGovernment: 'Return to local government',
    withdraw: 'Withdraw',
    assignedTo: 'Assigned to',
    assignedToMe: 'Assigned to me',
  },
  HEADER_MENU: {
    home: 'Home',
    applications: 'Applications',
    allocationsPayments: 'Allocations & payments',
    sepoHeader: 'Expenditures & outcomes',
    municipalInfo: 'My municipal info',
    logout: 'Logout',
    signout: 'Sign out',
    loginToSaml: 'Login to Saml',
    maConnect: 'MAConnect',
    lgffResources: 'LGFF resources',
  },
  NAVIGATION: {
    organization: 'Organizations',
    contact: 'Contacts',
    costCentre: 'Cost Centre',
    programBudget: 'Program Budget',
    allocation: 'Allocations',
    municipalTotals: 'Municipal Totals',
    municipalTotalsDetail: 'Municipal Totals Detail',
    applications: 'Applications',
  },
  CONTACT: {
    title: 'Contacts',
    contactName: 'Contact Name',
    contactType: 'Type',
    phoneNumber: 'Phone Number',
    email: 'Email',
    LGCode: 'LG Code',
    legalName: 'Legal Name',
    party: 'Party',
    statusCode: 'Status',
    titleSalutation: 'Title (Salutation)',
    workTitle: 'Work Title',
    officialTitle: 'Official Title',
    SimsLabel: 'SIMS',
    name: 'Name',
    statusReason: 'Status / Reason',
    statusAndReason: 'Status / Reason',
    stakeholderSimsId: 'Stakeholder ID',
    constituency: 'Constituency',
    salutation: 'Title (Salutation)',
    rank: 'Rank',
    extension: 'Phone Ext',
    addressLine1: 'Address Street 1',
    addressLine2: 'Address Street 2',
    city: 'City',
    province: 'Province',
    postalCode: 'Postal Code',
    combinedAddress: 'Address',
  },
  AUDIT: {
    title: 'Audit history',
    objectChangedAt: 'Changed Date',
    updatedAt: 'Changed Date',
    objectChangedBy: 'Changed By',
    updatedBy: 'Changed By',
    eventType: 'Event',
    fieldName: 'Changed Field',
    oldValue: 'Old Value',
    newValue: 'New Value',
    createdBy: 'Created by',
    lastUpdatedBy: 'Last updated by',
    searchPlaceHolder: 'Search',
    action: 'Action',
  },
  ORGANIZATION: {
    title: 'Organizations',
    name: 'Stakeholder Name',
    legalName: 'Legal Name',
    lgCode: 'LG Code',
    phoneNumber: 'Phone Number',
    sortNumber: 'Sort Code',
    typeCode: 'Type',
    statusCode: 'Status',
    contactLabel: ' Contact',
    SimsLabel: 'SIMS',
    email: 'Email',
    webSite: 'Website',
    orgEmail: 'Email',
    orgWebSite: 'Website',
    orgAddress: 'Address',
    businessPartnerId: 'Business Partner ID',
    precedenceOrder: 'Precedence Order',
    orgTypeTypeCode: 'Type / Code',
    commonName: 'Common Name',
    organizationType: 'Code',
    addressLine1: 'Address Street 1',
    addressLine2: 'Address Street 2',
    city: 'City',
    province: 'Province',
    postalCode: 'Postal Code',
    combinedAddress: 'Address',
  },
  ORGANIZATION_AUDIT: {
    title: 'Audit History',
  },
  COST_CENTRE: {
    title: 'Cost Centre',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    newBtnLabel: 'New cost centre',
    newDialogTitle: 'New Cost Centre',
    editDialogTitle: 'Edit Cost Centre',
    workNotes: 'Work Notes',
    workNoteOneStr: 'Work Notes',
    history: 'History',
    accountName: 'Account Name',
    profitCentre: 'Profit Centre',
    accountCode: 'Account Code',
    fund: 'Fund',
    wbsElement: 'WBS Element',
    costCentre: 'Cost Centre',
    accountname: 'Account name',
    profitcentre: 'Profit centre',
    accountcode: 'Account code',
    wbselement: 'WBS element',
    costcentre: 'Cost centre',
    costCentreType: 'Type',
    accrual: 'Accrual',
    current: 'Current',
    refund: 'Refund',
    statusCode: 'Status',
    pleaseSelect: 'Select type of account',
    errorMsgAccountNameRequired: 'Enter an account name',
    errorMsgProfitCentreRequired: 'Enter a profit centre',
    errorMsgAccountCodeRequired: 'Enter an account code',
    errorMsgFundRequired: 'Enter a fund',
    errorMsgWBSElementRequired: 'Enter a WBS element',
    errorMsgCostCentreTypeRequired: 'Select type of account.',
    errorMsgCostCentreRequired: 'Enter a cost centre',
    errorMsgAccountNameMaxLength: 'Account name should not exceed 50 characters.',
    errorMsgAccountCodeMaxLength: 'Account code should not exceed 50 characters.',
    errorMsgWorkNotesMaxLength: 'Work notes should not exceed 500 characters.',
    errorMsgAccountNameDuplicated: 'Enter a unique account name.',
    errorMsgAccountCodeDuplicated: 'Enter a unique account code.',
    active: 'Active',
    inactive: 'Inactive',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  },
  COST_CENTRE_DRAWER: {
    accountName: 'Account name',
    profitCentre: 'Profit centre',
    accountCode: 'Account code',
    wbsElement: 'WBS element',
    costCentre: 'Cost centre',
    costCentreType: 'Type',
    fund: 'Fund',
    note: 'Cost centre details are from the time the payment was created.',
  },
  PROGRAM_BUDGET: {
    title: 'Program Budget',
    newDialogTitle: 'New Program Budget',
    editDialogTitle: 'Program budget',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    newBtnLabel: 'New program budget',
    idTxt: 'Budget ID',
    programYear: 'Program Year',
    budget: 'Budget',
    supplementalBudget: 'Supplemental Budget',
    supplementalBudgetEditLabel: 'Supplemental Budget',
    programSupBudget: 'Supp. Budget',
    totalBudget: 'Total Budget',
    workNotes: 'Work Notes',
    accountCodeCurrent: 'Current Account Code',
    accountCodeAccrual: 'Accrual Account Code',
    accountCodeRefund: 'Refund Account Code',
    allocations: 'Allocations',
    allocationSuppl: 'Supp. Allocs.',
    allocationTotal: 'Total Allocs.',
    payments: 'Payments',
    paid: 'Paid',
    unPaid: 'Unpaid',
    refund: 'Refund',
    errorMsgProgramYearRequired: 'Select the program year.',
    errorMsgBudgetRequired: 'Enter budget amount.',
    errorMsgBudgetMin: 'Budget amount should be greater than 0.',
    errorMsgBudgetMaxLength: 'Budget amount should not exceed 15 digits.',
    errorMsgSupplementalBudgetMin: 'Supplement budget amount should be greater than 0.',
    errorMsgSupplementalBudgetMaxLength: 'Supplement budget amount should not exceed 15 digits.',
    deleteNoRecoverMsg: "You won't be able to recover it.",
    deleteBudgetTitle: "Delete the 'pbIdTxt' Program Budget?",
  },
  ALLOCATION: {
    title: 'Allocations',
    newWorkNoteTitle: 'New Work Note',
    payTitle: 'Payment',
    addNoteLabel: 'Add Note',
    btnSaveLabel: 'Save',
    idTxt: 'Allocation ID',
    programYear: 'Program Year',
    orgName: 'Organization',
    name: 'Organization',
    allocateTotal: 'Total Allocation',
    unPaid: 'Unpaid',
    reFund: 'Refund',
    lgCode: 'LG Code',
    programBudgetId: 'Budget Id',
    allocation: 'Allocation',
    paid: 'Paid',
    paidDt: 'Allocate Paid Date',
    supplementalPaid: 'Allocate Supp. Paid',
    supplementalPaidDate: 'Allocate Supp. Paid Date',
    refund: 'Allocate Refund Amount',
    refundGet: 'Allocate Refund Collected',
    refundGetDt: 'Allocate Refund Received Date',
    supplementalAllocation: 'Supp. Allocation',
    importAllocTitle: 'Import Allocations',
    importAllocBtn: 'Import file',
    uploadAFile: 'Upload a file',
    csvOnly: '(CSV files only)',
    workNotes: 'Work Notes',
    workNoteOneStr: 'Work Notes',
    history: 'History',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  },
  APPLICATION: {
    title: 'Applications',
    idTxt: 'Application ID',
    orgName: 'Organization',
    assignedTo: 'Assigned to',
    batchName: 'Batch ID',
    actions: 'Actions',
    searchPlaceHolder: 'Search Applications',
    resultsPerPage: 'Results per page',
    of: 'of',
    submittedAtStr: 'Submitted on',

    // below most used for application-all-detail-export-to-csv-column-headers
    name: 'Application name',
    description: 'Application description',
    organizationLegalName: 'Organization',
    organizationLgCode: 'LG Code',
    status: 'Portal application status',
    stage: 'Stage',
    stageTitle: 'Stage',
    assignedToName: 'Assigned to',
    ministerDescription: 'Minister description',
    programYear: 'Program year',
    primaryOutcome: 'Primary outcome',
    contactFullName: 'Contact name',
    contactEmailAddress: 'Contact email address',
    contactPhoneNumber: 'Contact phone number',
    primaryFunctionalCategory: 'Primary functional category',
    additionalPrimaryCategories: 'Additional functional category(s)',
    // additionalFunctionalCategories: 'Additional functional category(s)',
    totalEstimatedCost: 'Total estimated cost',
    totalLgffFundingRequested: 'Total LGFF funding requested',
    totalamountFromOtherGrantPrograms: 'Total funding from other grants',
    totalAmountFromMunicipalSources: 'Total funding from municipal sources',
    fundingFromOther: 'Other funding sources',
    localGovernmentOwnership: 'Capital assets ownership',
    submittedAt: 'Submitted on',
    // submittedBy: 'Submitted by',
    certifiedByFullName: 'Submitted by',
    recommendedAt: 'Recommended on',
    recommendedBy: 'Recommended by',
    recommendedByName: 'Recommended by',
    acceptedAt: 'Accepted on',
    acceptedAtFormatted: 'Accepted Date',
    acceptedBy: 'Accepted by',
    withdrawnAt: 'Withdrawn on',
    withdrawnBy: 'Withdrawn by',
    createdAt: 'Created on',
    createdBy: 'Created by',
    updatedAt: 'Updated on',
    updatedBy: 'Updated by',

    // below most used for application-project-detail-export-to-csv-column-headers
    applicationIdTxt: 'Application ID',
    projectIdTxt: 'Project Id',
    projectDescription: 'Description',
    projectName: 'Project name',

    address: 'Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    primaryCapitalAsset: 'Primary capital asset',
    additionalCapitalAssets: 'Additional capital assets',

    estimatedTotalCost: 'Estimated total cost',
    amountRequestedFromLGFF: 'Amount requested from LGFF',
    amountFromOtherGrantPrograms: 'Amount from other grant programs',

    anticipatedStartDate: 'Anticipated start date',
    anticipatedEndDate: 'Anticipated end date',

    projectType: 'Project type',
    currentAssetCondition: 'Current asset condition',
    quantityNew: 'Quantity new',
    quantityUpgrade: 'Quantity upgrade',
    showNumberResult: 'Showing 1 result.',
    showNumberResults: 'Showing %% results.',
    backToMTApp: 'Back to Municipal totals application page',
    statusTitle: 'Application status',
    organizationTxt: 'Organization',
    totalLgffFundingRequestedValidationError: 'Maximum funding should be greater or equal than minimum funding.',
    totalLgffFundingRequestedMinimum: 'Minimum ($)',
    totalLgffFundingRequestedMaximum: 'Maximum ($)',
    pleaseSelectOrg: 'Please select an organization',
    pleaseSelectProgramYear: 'Please select a program year',
    pleaseSelectStage: 'Please select a stage',
  },
  APPLICATION_BATCH: {
    title: 'Application batches',
    name: 'Batch ID',
    assignedToName: 'Assigned to',
    stageTitle: 'Batch stage',
    programYear: 'Program year',
    acceptedAtString: 'Accepted on',
    acceptedByName: 'Accepted by',
    createdAtString: 'Created on',
    updatedByName: 'Updated by Name',
    updatedBy: 'Updated by',
    updatedAt: 'Updated on',
    createdAt: 'Created on',
    createdByName: 'Created by Name',
    createdBy: 'Created by',
    applicationCount: 'Count',
    assignedToId: 'Assigned to id',
    acceptedBy: 'Accepted by',
    acceptedAt: 'Accepted on',
    id: 'ID',
  },
  PAYMENT: {
    title: 'Payments',
    searchPlaceHolder: 'Search Payments',
    tabLabelAll: 'All',
    tabLabelNew: 'New',
    tabLabelNewInBatch: 'New in Batch',
    tabLabelPaymentInProgress: 'Payment in Progress',
    tabLabelPaidReconciled: 'Paid Reconciled',
    idTxt: 'Reference number',
    allocationIdTxt: 'Linked allocation',
    programYear: 'Program year',
    organizationTxt: 'Organization',
    amount: 'Amount',
    statusTitle: 'Payment status',
    paidOnString: 'Paid on',
    oneGXPaymentId: '1GX payment ID',
    paymentBatchId: 'Linked payment batch',
    pleaseSelectOrg: 'Please select an organization',
    pleaseSelectProgramYear: 'Please select a program year',
    pleaseSelectStatus: 'Please select a status',
    accountName: 'Cost centre type',
  },
  PAYMENT_BATCH: {
    title: 'Payment batches',
    searchPlaceHolder: 'Search',
    tabLabelAll: 'All',
    tabLabelNew: 'New',
    tabLabelPaymentInProgress: 'Payment in Progress',
    tabLabelUnderReview: 'Under Review',
    tabLabelEoRecommendation: 'EO Recommendation',
    tabLabelAoApproval: 'AO Approval',
    tabLabelReconciled: 'Reconciled',
    idTxt: 'Payment batch ID',
    stageTitle: 'Batch stage',
    numberOfPayments: '# of payments',
    total: 'Batch payment total',
    recommendedByName: 'Recommended by',
    recommendedAt: 'Recommended on',
    approvedByName: 'Approved by',
    approvedAt: 'Approved on',
    updatedByName: 'Updated by',
    updatedAt: 'Last updated on',
    accountName: 'Cost centre type',
  },
  PAYMENT_BATCH_DETAIL: {
    idTxt: 'Reference number',
    programYear: 'Program year',
    organizationTxt: 'Organization',
    amount: 'Amount',
    paidOnString: 'Paid on',
    statusTitle: 'Status',
    oneGXPaymentId: '1GX payment ID',
    submitBatchLabel: 'Submit batch',
    areYouSureCompleteAdmin: "Are you sure you'd like to submit the batch to the Payment Reviewer?",
    recommendedGuideline:
      'Grant payment criteria has been met in accordance with applicable legislation and regulation, which may also include program guidelines and grant agreements. Amounts have not previously been paid.',
    approvedGuideline:
      'Disbursements certified pursuant to Section 37 of the Financial Administration Act, 1977. Processing of attached transactions approved as being in accordance with this Act and directives prescribed thereunder.',
    areYouSureCompleteReviewer: "Are you sure you'd like to complete the review and send it to the EO?",
    completeBatchReviewerLabel: 'Complete review',
    areYouSureRecommendBatchEO: "Are you sure you'd like to recommend the batch and send it to the AO?",
    completeRecommendBatchEOLabel: 'Recommend batch',
    areYouSureApproveBatch: "Are you sure you'd like to approve the batch?",
    completeApproveBatchLabel: 'Approve batch',
    costCentreType: 'Cost centre type',
  },
  EXPORT_PAYMENT_BATCH_SUMMARY_CSV: {
    lgCode: 'LG Code/SID#',
    organizationlegalName: 'Organization',
    remitMessage: 'Remit Message',
    costCenter: 'Cost Center Code',
    referenceNumber: 'Reference Number',
    amount: 'Amount',
    programYear: 'Program year',
    paymentBatchId: 'Linked Payment Batch',
    dueAt: 'Payment Due Date',
    oneGXPaymentId: '1GX Payment ID',
  },
  MUNICIPAL_TOTALS: {
    title: 'Municipal Totals',
    municipalId: 'Municipal ID',
    orgName: 'Organization',
    allocTotal: 'Total Allocations ($)',
    allocPaid: 'Paid ($)',
    certExp: 'Cert. Exp',
    certCredit: 'Cert. Credit',
    certLastSfeYr: 'Last Cert. SEPO Yr',
    backToMT: 'Back to Municipal totals',
    details: 'Financial summary',
    allocations: 'Allocations',
    applications: 'Applications',
    SFEs: 'SFEs',
    SEPO: 'SEPO',
    payments: 'Payments',
    contacts: 'Contacts',
    attachments: 'Attachments',
    mtTotalLabel: 'Municipal Totals',
  },
  EXPORT_POPUP: {
    title: 'Export',
    cancelBtn: 'Cancel',
    exportBtn: 'Export',
    currentColumns: 'Current columns',
    allData: 'All data',
  },
  EXPORT_POPUP_APP: {
    title: 'Export',
    cancelBtn: 'Cancel',
    exportBtn: 'Export',
    currentColumns: 'Current view', // 'Current columns',
    allData: 'All data (Applications detail only)',
    allPrjs: 'All data (Projects details only)',
  },
  MUNICIPAL_DETAILS: {
    applicationSummaryTitle: 'Application and Financial Summary',
    fundingUnderReview: 'Funding Under Review',
    fundingRecommended: 'Funding Recommended',
    totalAccepted: 'Total Accepted (In progress)',
    totalExpended: 'Total Expended (CFF)',
    totalFundingAvailable: 'Total Funding Available',

    uncommittedFunds: 'Uncommitted Funds',
    applicationsSubmitted: 'Applications Submitted',
    activeProjects: 'Active Applications',
    closedProjects: 'Closed Applications',

    sfeSummaryTitle: 'SEPO Summary',
    certifiedExpendituresToDate: 'Certified Expenditures To Date',
    certifiedCreditItemsToDate: 'Certified Credit Items To Date',
    unspentAmountToDate: 'Unspent Amount To Date',
    lastCertifiedSepoYear: 'Last Certified SEPO Year',

    paymentsTitle: 'Allocations and Payments',
    totalAllocationsToDate: 'Total Allocations To Date',
    totalPaid: 'Total Paid',
    totalUnpaid: 'Total Unpaid',
    lastPaymentDate: 'Last Payment Date',

    workNotesTitle: 'Work Notes',
    addWorkNoteButton: '+ Add Work Note',
    auditHistoryTitle: 'Audit History',

    validationConditions: 'Validation conditions',
    validationConditionsSuccessMessage: 'Validation conditions succesfully saved.',
    moaExecutedLabel: 'MOA executed',
    msiCertifiedLabel: '2023 MSI SFE certified',
    msiAllocationLabel: '2023 MSI allocation paid and carry-forward less than current year allocation',
  },
  MUNICIPAL_ALLOCATION: {
    idTxt: 'Allocation ID',
    programYear: 'Program year',
    totalAllocation: 'Total allocation',
    paid: 'Total paid',
    paidDate: 'Paid date',
  },
  MUNICIPAL_CONTACT: {
    fullName: 'Contact name',
    contactType: 'Type',
    phoneNumber: 'Phone number',
    email: 'Email',
    statusCode: 'Status',
  },
  APPLICATION_DETAIL: {
    applicationDetailHeading: 'Application details',
    backToapplicationLabel: 'Back to Applications',
    fundingFromAMWWP: 'Alberta Municipal Water/Wastewater Partnership (AMWWP)',
    fundingFromCCBF: 'Canada Community-Building Fund (CCBF)',
    fundingFromSTIP: 'Strategic Transportation Infrastructure Program (STIP)',
    fundingFromWaterForLife: 'Water for Life (W4L)',
    fundingFromMSI: 'Municipal Sustainability Initiative (MSI)',
    localGovernment: 'Local government',
    otherFundingSources: 'Other: ',
    applicationNameRequiredError: 'Application name is required',
    applicationNameSuccessMessage: 'Application name successfully updated',
    applicationNameHelpTextMessage:
      'Create a name that represents the project or group of projects being submitted under this application.',
    applicationDescriptionRequiredError: 'Application description is required',
    applicationDescriptionMaxLengthError: 'Application description cannot be greater than 250 characters',
    applicationDescriptionSuccessMessage: 'Application description successfully updated',
    applicationDescriptionHelpTextMessage:
      'Provide details to determine eligibility, including capital asset(s) and activities.',
    applicationMinisterDescriptionRequiredError: 'Application minister description is required',
    applicationMinisterDescriptionMaxLengthError:
      'Application minister description cannot be greater than 250 characters',
    applicationMinisterDescriptionSuccessMessage: "Minister's description successfully updated",
    applicationMinisterDescriptionHelpTextMessage: '',
    returnApplicationToGAModalTitle: 'Return to Grant Advisor',
    sureReturnToGAMsg: "Are you sure you'd like to return the application back to the Grant Advisor?",
    returnApplicationToGAWorkNoteHelpText:
      'Add comments about why the application is being returned to the Grant Advisor. This work note will only be viewable by internal staff.',
    workNote: 'Work note',
    applicationReturnToGAAssignedToErrorMsg: 'Please choose an assignee',
    yesReturnToGA: 'Yes, return to Grant Advisor',
    applicationReturnToGAAssignedTo: "Application moved to 'Under Review' stage and assigned to %%.",
  },
  PROJECT_DETAIL: {
    backToApplicationDetailsLabel: 'Back to Application details',
    projectDetail: 'Project details',
    applicationInformation: 'Application Information',
    programYear: 'Program year',
    applicationID: 'Application ID',
    applicationStage: 'Application stage',
    projectInformation: 'Project information',
    projectStatus: 'Project status',
    projectName: 'Project name',
    projectNameSuccessMessage: 'Project name successfully updated',
    projectDescription: 'Brief project description',
    projectDescriptionSuccessMessage: 'Project description successfully updated',
    projectDescriptionRequiredError: 'Project description is required',
    projectDescriptionMaxLengthError: 'Project description cannot be greater than 250 characters',
    primaryCapitalAsset: 'Primary capital asset',
    additionalCapitalAssets: 'Additional capital asset(s)',
    projectType: 'Project type:',
    quantityNew: 'Quantity new:',
    quantityUpgrade: 'Quantity upgrade:',
    assetCondition: 'Asset condition',
    projectDate: 'Project date',
    startDate: 'Start:',
    endDate: 'End:',
    location: 'Location of capital asset',
    auditHistory: 'Audit history',
    auditHistoryCreated: 'Created',
    auditHistoryLastUpdated: 'Last updated',
  },
  INDIVIDUAL_REVIEW: {
    ministerDescription: "Minister's description",
    lgffFundedAmountMinimumThresholdReached:
      '1. Does the LGFF-funded amount meet or exceed the minimum application threshold amount?',
    municipalityHasEnoughUncommittedFunding:
      '2. Does the municipality have enough uncommitted LGFF funding for the application being recommended?',
    qualityOfSubmittedApplication: '3. What is the as-submitted quality of the application?',
    dateApplicationConsideredReadyForReview:
      '4. On what date was the application considered clean and ready for review?',
    adequateProjectDescriptionsProvided:
      '5. Does the application provide an adequate description of the work and/or activities for each of the respective project(s) listed?',
    projectAndActivitiesMeetProgramEligibility:
      '6. Are the listed project(s) and activities on the application eligible as per the program guidelines?',
    intendedPrimaryOutcomeAccepted:
      '7. Does the intended primary outcome make sense based on the application description?',
    anticipatedStartAndEndDatesComply:
      '8. Do the anticipated project start and end dates comply with program guidelines?',
    fullyReviewedAndNoIssuesRemain:
      '9. Has the application been fully reviewed and all issues and/or clarification items addressed?',
    qualifyForSignageCommunicationRequirements:
      '10. Does this application qualify for signage and/or communication requirements?',
    systemValidationErrorMessage:
      'This application cannot pass with this answer. If an override applies, override and add a comment to pass this application.',
    overrideErrorMessage:
      'This application cannot pass with this answer. If an override applies, override and add a comment to pass this application.',
    overrideCommentErrorMessage: 'Enter a comment',
    requiredAnswerErrorMessage: 'Select an answer.',
    inValidAnswerErrorMessage: 'Are you sure this answer is correct? The application cannot pass with this answer.',
    invalidDateApplicationConsideredForReviewErrorMessage:
      'Select a date between the application submitted date and today.',
    emptyMinisterDescriptionErrorMessage: 'Enter a description',
    lgffFundedAmountMinimumThresholdReachedOverride: 'Override question 1?',
    municipalityHasEnoughUncommittedFundingOverride: 'Override question 2?',
    lgffFundedAmountMinimumThresholdReachedOverrideComment: 'Minimum threshold override comment',
    municipalityHasEnoughUncommittedFundingOverrideComment: 'Maximum commitment override comment',
  },
  APPLICATION_SUMMARY: {
    applicationSummaryHeading: 'Application Summary',
  },
  BATCH_DETAIL: {
    batchDetailHeading: 'Application batch details',
    backToRecommendApplicationLabel: 'Back to Recommended Applications',
    acceptedOnError: 'Enter the date the batch was accepted on before approving the batch for the Minister.',
  },
  PAYMENT_DETAIL: {
    paymentDetailHeading: 'Payment details',
    remitMessageLabel: 'Remit message',
    remitMessage: 'Enter remit message',
    paidOn: 'Enter paid on date',
  },
  GRANT_APPLICATION: {
    bannerTitle: 'LGFF Capital application',
  },
  GENERAL_INFORMATION: {
    generalSectionHeading1: 'General information',
    generalSectionHeading2: 'Application contact information',
    appSectionHeading: 'Application information',
  },
  FUNCTIONAL_CATEGORIES: {
    functionalCategoriesHeading1: 'Functional categories',
    functionalCategoriesHeading3:
      'Choose a primary category for your projects in this application, indicate the percentage of the total cost and add it.',
    functionalCategoriesHeading4: 'You can repeat this process for additional categories if required.',
    primaryCategory: 'Primary category',
    additionalCategory: 'Additional category',
    primaryCategoryHelpText: 'Select a primary category for this application.',
    additionalCategoryHelpText: 'Select an additional category for this application if applicable.',
    cost: 'Percentage of total application cost',
    categoryProjectCallout: 'Changing your primary category will remove all projects on the Projects page.',
    categoryAdditionalCallout:
      'This is already selected as an additional category. Selecting it will remove it and change it to your primary category.',
    costHelpText:
      'Enter the percentage of the estimated total cost of all included projects for this functional category.',
    errorCalloutAdditionalCategoryHeading:
      'The following items require attention before you can add an additional category:',
    errorMsgCategoryRequired: 'Select a category.',
    errorMsgPercentageCostRequired: 'Enter the percentage of the total application cost.',
    errorMsgPrimaryMajorityPercentage:
      "The primary category percentage must be the majority of all of the projects' percentages.",
    errorMsgTotalPercentage: 'Percentages must add up to 100%.',
    errorMsgYourCatPercent: 'Your categories: % of total application cost',
    errorMsgAtLeastOneCategory: 'At least one category needs to be added.',
    otherSourceOfFundingErrorMsg: 'Select if there are other sources of provincial or federal grant funding.',
    noSourceSelectedErrorMsg: 'Select the other sources of provincial or federal grant funding.',
    otherNoSpecifiedErrorMsg: 'Specify the source of the provincial or federal grant funding.',
    assetOwnedByLocalGovErrorMsg: 'Select who will own the resulting asset(s) from this application.',
    npoNotSpecifiedErrorMsg: 'Specify the name of the non-profit organization.',
    regionalServiceCommissionNotSpecifiedErrorMsg: 'Specify the name of the Regional Service Commission.',
    otherLocalGovNotSpecifiedErrorMsg: 'Specify the name of the local government or group of local governments.',
    incompleteCatModalTitle: 'Incomplete category',
    incompleteCatModalContent:
      'There is an unfinished category ("%%") that hasn\'t been added. Are you sure you\'d like to proceed without it?',
    labelOtherSourceFunding: 'Are there other sources of provincial or federal grant funding?',
    labelWhoOwnAsset: 'Who will own resulting asset(s) from this application? Select all that apply.',
  },
  PROJECT: {
    errorMsgAtLeastOneProject: 'At least one project needs to be added.',
    incompleteProjectModalTitle: 'Incomplete project',
    incompleteProjectModalContent:
      'There is an unfinished project ("%%") that hasn\'t been added. Are you sure you\'d like to proceed without it?',
    errorDescriptionTwentyProjects: 'Please create a new application to submit additional projects.',
    errorTitleTwentyProjects: "You've added the maximum amount of projects for this application.",
    errorFundingGrantProgramTitle: 'The following items on this page require attention:',
    errorFundingGrantProgramDescription: "At least one project needs a value for 'Funding from other grant programs'.",
  },
  CASH_FLOW_UPDATES: {
    contactSectionHeading1: 'Contact information',
    contactSectionHeading2: 'Enter your application contact information',
    appSectionHeading: 'Application information',
    backToCFU: 'Back to Cash flow updates',
    CFUDetailHeading: 'Cash flow update details',
    appInfoTitle: 'Application information',
    appFundingTitle: 'Application funding',
    projectUpdatesTitle: 'Project updates',
    contactInfoTitle: 'Contact information',
    updatesTitle: 'Updates',
    workNotesTitle: 'Work notes',
    auditHistoryTitle: 'Audit history',
    previousAcceptedLGFFFundingTotal: 'Previous accepted LGFF funding total',
    updatedLGFFFundingTotal: 'Updated LGFF funding total',
    cfuVariance: 'Cash flow update variance',
    programYear: 'Program year',
    ministerDescription: "Minister's description",
    otherSourcesGrantFunding: 'Are there other sources of provincial or federal grant funding ?',
    reasonForUpdate: 'Reason for update',
    source: 'Other funding sources',
    previously: 'Previously',
    firstName: 'First name',
    lastName: 'Last name',
    phoneNum: 'Phone number',
    email: 'Email address',
    submitted: 'Submitted',
    recommended: 'Recommended',
    accepted: 'Accepted',
    withdrawn: 'Withdrawn',
    created: 'Created',
    lastUpdated: 'Last updated',
    noWorkNotes: 'There are no work notes at this time.',
    idTxt: 'Cash flow update ID',
    applicationIdTxt: 'Application ID',
    submittedAt: 'Submitted On',
    organizationLegalName: 'Organization',
    organizationLgCode: 'LG Code',
    updatedTotalLgffFundingAccepted: 'Updated LGFF funding total',
    stage: 'Stage',
    assignedToName: 'Assigned to',
    expandCol: '',
    projectIdTxt: 'Project ID',
    projectName: 'Project name',
    capitalAssetTypeTitle: 'Primary capital asset',
    additionalCapitalAsset: 'Additional capital asset',
    briefProjectDescription: 'Brief project description',
    amountRequestedFromLGFF_Previous: 'Previous accepted LGFF funding',
    anticipatedStartDate_Previous: 'Previous accepted anticipated start date',
    formattedAnticipatedStartDatePrevious: 'Previous accepted anticipated start date',
    title: 'Cash flow updates',
    searchPlaceHolder: 'Search cash flow updates',
    pleaseSelectStatus: 'Please select a stage',
    pleaseSelectOrg: 'Please select an organization',
    pleaseSelectProgramYear: 'Please select a program year',
    statusTitle: 'Cash flow update stage',
    organizationTxt: 'Organization',
    cfuMovedToUnderReview: "Cash flow update moved to 'Under Review' stage.",
    cfuMovedToRecommended: "Cash flow update moved to 'Recommended' stage and unassigned.",
    cfuMovedToAccepted: "Cash flow update moved to 'Accepted' stage.",
    approveCFUModalTitle: 'Approve cash flow update',
    sureApproveCFUMsg: "Are you sure you'd like to approve the cash flow update?",
    yesApproveCFU: 'Yes, approve cash flow update',
    recommendCFUModalTitle: 'Recommend cash flow update',
    sureRecommendCFUMsg: "Are you sure you'd like to recommend the cash flow update?",
    yesRecommendCFU: 'Yes, recommend cash flow update',
    cfuReturnToGAAssignedTo: "Cash flow update moved to 'Under Review' stage and assigned to %%.",
    cfuReturnToGAAssignedToErrorMsg: 'Please choose an assignee',
    sureWithdrawMsg: "Are you sure you'd like to withdraw the cash flow update ",
    returnCFUToGAWorkNoteHelpText:
      'Add comments about why the cash flow update is being returned to the Grant Advisor. This work note will only be viewable by internal staff.',
    withdrawCFUWorkNoteHelpText:
      'Add comments about why the cash flow update is being withdrawn. This work note will only be viewable by internal staff.',
    returnCFUToLGWorkNoteHelpText:
      'Add comments about why the cash flow update is being returned to the local government. This work note will only be viewable by internal staff.',
    workNote: 'Work note',
    sureReturnToGAMsg: "Are you sure you'd like to return the cash flow update back to the Grant Advisor?",
    sureReturnToLGMsg: "Are you sure you'd like to return the cash flow update ",
    returnCFUToGAModalTitle: 'Return to Grant Advisor',
    returnCFUToLGModalTitle: 'Return application to local government',
    returnedCFUToLGMsg: 'Cash flow update successfully returned to local government.',
    withdrawCFUModalTitle: 'Withdraw cash flow update',
    workNoteErrorMsg: 'Enter a work note.',
    workNoteErrorLengthMsg: 'Work note character length should be between 0 and 500.',
    workNoteErrorLength250Msg: 'Work note character length should be between 0 and 250.',
    cfuWithdrawnMsg: 'Cash flow update %% withdrawn.',
    errorCalloutHeading: 'The following items on this page require attention:',
    yesReturnToGA: 'Yes, return to Grant Advisor',
    yesWithdraw: 'Yes, withdraw',
    yesReturnToLG: 'Yes, return to local government',
    cfuReturnedToLG: 'Cash flow update returned to local government',
    cfuWithdrawn: 'Cash flow update withdrawn',
    withdrawProject: 'Withdraw project',
    noChange: 'No changes.',
    assignedToMe: 'Assign to me',
  },
  CASH_FLOW_UPDATES_EXPORT: {
    idTxt: 'Cash flow update ID',
    applicationIdTxt: 'Application ID',
    applicationName: 'Application name',
    projectIdTxt: 'Project ID',
    projectName: 'Project name',
    organizationLegalName: 'Organization',
    organizationLgCode: 'LG Code',
    programYear: 'Program year',
    ministerDescription: 'Minister description',
    stageTitle: 'Stage',
    contactFullName: 'Contact name',
    contactEmailAddress: 'Contact email address',
    contactPhoneNumber: 'Contact phone number',
    updatedTotalLgffFundingAcceptedTxt: 'Updated LGFF funding total',
    fundingFromOther_Previous: 'Other funding sources (Previously)',
    fundingFromOther_Updated: 'Other funding sources (Updated)',
    areThereOtherSourcesOfFunding_Previous: 'Are there other sources of funding (Previously)',
    areThereOtherSourcesOfFunding_Updated: 'Are there other sources of funding (Updated)',
    cashFlowUpdateIdTxt: 'Cash flow update ID',
    estimatedTotalCost_Previous: 'Estimated total cost (Previously)',
    estimatedTotalCost_Updated: 'Estimated total cost (Updated)',
    amountRequestedFromLGFF_Previous: 'Amount requested from LGFF (Previously)',
    amountRequestedFromLGFF_Updated: 'Amount requested from LGFF (Updated)',
    amountFromOtherGrantPrograms_Previous: 'Amount from other grant programs (Previously)',
    amountFromOtherGrantPrograms_Updated: 'Amount from other grant programs (Updated)',
    totalAmountFromMunicipalSources_Previous: 'Total funding from municipal sources (Previously)',
    totalAmountFromMunicipalSources_Updated: 'Total funding from municipal sources (Updated)',
    anticipatedStartDate_Previous: 'Anticipated start date (Previously)',
    anticipatedStartDate_Updated: 'Anticipated start date (Updated)',
    anticipatedEndDate_Previous: 'Anticipated end date (Previously)',
    anticipatedEndDate_Updated: 'Anticipated end date (Updated)',
    projectType: 'Project type',
    quantityNew_Previous: 'Quantity new (Previously)',
    quantityNew_Updated: 'Quantity new (Updated)',
    quantityUpgrade_Previous: 'Quantity upgrade (Previously)',
    quantityUpgrade_Updated: 'Quantity upgrade (Updated)',
    reasonForUpdate: 'Reason for update',
    reasonForFundingUpdate: 'Reason for funding update',
    totalUpdatedVarianceAmount: 'Total update variant amount',
    capitalAssetTypeTitle: 'Primary capital asset',
    previousTotalLgffFundingAccepted: 'Total LGFF funding accepted (Previously)',
    updatedTotalLgffFundingAccepted: 'Total LGFF funding accepted (Updated)',
    assignedToName: 'Assigned to',
    recommendedAt: 'Recommended on',
    recommendedBy: 'Recommended by',
    submittedAt: 'Submitted on',
    submittedBy: 'Submitted by',
    acceptedAt: 'Accepted on',
    acceptedAtFormatted: 'Accepted Date',
    acceptedBy: 'Accepted by',
    withdrawnAt: 'Withdrawn on',
    withdrawnBy: 'Withdrawn by',
    createdAt: 'Created on',
    createdBy: 'Created by',
    updatedAt: 'Updated on',
    updatedBy: 'Updated by',
  },
  CERTIFICATION: {
    heading1: 'Certification',
    heading2: 'Certify and submit your application',
    cashflowHeading2: 'Certify and submit your cash flow update',
    cfuHeading2: 'Certify your update',
    sectionHeading: 'How do you want to certify your application?',
    selfAcceptance:
      'I, %username%, the CAO and/or Administrator of %organization%, certify that the information contained in this application is correct, that all LGFF funds will be used in accordance with the LGFF Capital Program Guidelines and the LGFF Memorandum of Agreement, that these expenses have not been deemed eligible on a prior LGFF application, and that the grant amount will be applied in the year and manner described above once this application has been accepted by the Minister.',
    cashFlowCertifyMessage:
      "I, %%, from %%, confirm this cash flow update does not materially alter the project's scope in this application. All LGFF funds will be used according to the LGFF Capital Program Guidelines and Memorandum of Agreement. These expenses were not approved in another LGFF application, and the grant amount will be used as described.",
    representativeAcceptance:
      'As a representative of %organization%, I, %username%, have been authorized to submit this application on behalf of the CAO and/or Administrator and have certified that the information contained in this application is correct, that all LGFF funds will be used in accordance with the LGFF Capital Program Guidelines and the LGFF Memorandum of Agreement, that these expenses have not been deemed eligible on a prior LGFF application, and that the grant amount will be applied in the year and manner described above once this application has been accepted by the Minister.',
    legalDisclaimerTitleTxt: 'Legal Disclaimer',
    legalDisclaimerTxt:
      'The personal information provided on this form or on any attachments is required for the purpose of determining eligibility for the Local Government Fiscal Framework (LGFF) and the administration of the program. Your personal information is collected under the authority of section 33(c) of the Freedom of Information and Protection of Privacy (FOIP) Act and will be managed in accordance with the privacy provisions under the FOIP Act. If your grant application is approved, your name, the grant program and the amount of the grant may be published on the Government of Alberta Grant Disclosure Portal as authorized under section 40(1)(b) and (f) of the FOIP Act. Should you have any questions about the collection, use or disclosure of this information, please contact the Grant Program Delivery Unit at 780-422-7125 or toll free by first dialing 310-0000, or by email at',
    email: 'ma.lgffcapital@gov.ab.ca',
    downloadPdfLabel: 'Download a PDF of your application',
    selectACertificationErrorMsg: 'Select a certification',
    selectCashFlowCertificationErrorMsg: 'Check the box to confirm.',
    modalSubmitCashFlowHeadingId: 'Submit cash flow update',
    modalSubmitCashFlowContent: "Are you sure you'd like to submit this cash flow update? It can't be edited later.",
    yesSubmitBtn: 'Yes, submit',
    submittingCashFlow: 'Submitting cash flow...',
    submitCashflowSuccesSnack: 'Cash flow update successfully submitted.',
  },
  VIEW_APPLICATIONS: {
    bannerTitle: 'LGFF Capital applications',
    viewApplicationsHeading: 'Your applications',
    viewApplicationThreshold: 'Minimum application threshold',
    newApplicationBtnText: 'New application',
  },
  DRAFT_APPLICATIONS: {
    name: 'Application name',
    primaryFunctionalCategoryTitle: 'Primary functional category',
    primaryOutcomeTitle: 'Primary outcome',
    createdAt: 'Created date',
    actions: 'Actions',
    deleteSuccessConfirm: 'Your "%%" application was successfully deleted.',
  },
  RETURNED_APPLICATIONS: {
    name: 'Application name',
    number: 'Application number',
    primaryFunctionalCategory: 'Primary functional category',
    primaryOutcome: 'Primary outcome',
    returnedDate: 'Returned date',
    actions: 'Actions',
    deleteSuccessConfirm: 'Your "%%" application was successfully withdrawn.',
  },
  MODEL_CANCEL_DELETE_DRAFT_APP: {
    title: 'Cancel and delete application',
    body: "Are you sure you want to cancel and delete this application? This can't be undone.",
    btnNo: 'No, go back to the application',
    btnYes: 'Yes, cancel and delete',
  },
  MODEL_CANCEL_WITHDRAW_RETURNED_APP: {
    title: 'Cancel and withdraw application',
    body: "Are you sure you want to cancel and withdraw this application? This can't be undone.",
    btnNo: 'No, go back to the application',
    btnYes: 'Yes, cancel and withdraw',
  },
  MODEL_CANCEL_DELETE_DRAFT_IN_LIST: {
    title: 'Delete the "%%" application?',
    body: "You won't be able to recover it.",
    btnNo: 'Cancel',
    btnYes: 'Delete',
  },
  MODEL_CANCEL_WITHDRAW_RETURNED_IN_LIST: {
    title: 'Withdraw the "%%" application?',
    body: "You won't be able to recover it.",
    btnNo: 'Cancel',
    btnYes: 'Withdraw',
  },
  MODEL_IDLE_TIMEOUT: {
    titleMain: 'Session timeout',
    titleSub: 'Your session will expire in "%%" seconds. Would you like to continue your session or sign out?',
    btnSignOut: 'Sign out',
    btnContinueSession: 'Continue session',
  },
  HOME: {
    bannerTitle: 'Welcome to LGFF Capital',
    announcementsHeading: 'Announcements',
    announcementATitle: 'LGFF Online Portal',
    announcementALine1:
      'Municipal Affairs LGFF Online Portal allows local governments to submit their LGFF applications and view their allocation(s) and payment information.',
    announcementALine2:
      'In addition, local governments are now able to submit cash flow updates by using the pencil icon beside their approved projects. LGFF does not permit amendments (i.e. scope change); however, a local government can update the total project costs, funding sources, start and end date, and the resulting asset quantity.',
    announcementALine3:
      'Please note that the portal is still under development and certain features may not be accessible at this time but will be made available in the future.',

    applicationsHeading: 'Applications',
    applicationSubHeading: 'Create and manage your applications.',
    viewApplicationButtonLabel: 'View applications',
    allocationsPaymentsHeading: 'Allocations and payments',
    allocationsPaymentsSubHeading: 'View allocation and payment information.',
    viewAllocationsPaymentsButtonLabel: 'View allocations and payments',
    toolsAndReportsHeading: 'Tools and reports',
    toolsAndReportSubHeading: 'View general reports.',
    viewToolsAndReportsButtonLabel: 'View Tools and reports',
    lgffResourcesHeading: 'LGFF resources',
    viewlgffResourcesButtonLabel: 'View LGFF resources',
    lgffWebsite: 'LGFF website',
    lgffWebsiteLink: 'https://www.alberta.ca/local-government-fiscal-framework-capital-funding',
    lgffGuidelines: 'LGFF guidelines',
    lgffGuidelinesLink: 'https://open.alberta.ca/publications/local-government-fiscal-framework-program-guidelines',
    msiOperating: 'MSI Operating',
    msiOperatingLink: 'https://www.maconnect.alberta.ca/MAConnect/login',
    moreInformationText: 'Use your Alberta.ca Account for Organizations (formerly MADI-B) to login.',
    organizationSignUpGuideLink:
      'https://www.alberta.ca/system/files/ti-alberta-ca-account-for-organizations-reference-guide.pdf',
    sepoHeading: 'SEPOs',
    sepoSubHeading: ' Submit and manage your SEPOs.',
    viewSepoButtonLabel: 'View SEPOs',
  },
  SUBMITTED_APPLICATIONS: {
    name: 'Application name',
    appNumber: 'Application number',
    primaryFunctionalCategoryTitle: 'Primary functional category',
    primaryOutcomeTitle: 'Primary outcome',
    submittedDate: 'Submitted date',
    actions: 'Actions',
  },
  ACCEPTED_APPLICATIONS: {
    name: 'Application name',
    appNumber: 'Application number',
    primaryFunctionalCategoryTitle: 'Primary functional category',
    primaryOutcomeTitle: 'Primary outcome',
    acceptedDate: 'Accepted date',
    actions: 'Actions',
  },
  WITHDRAWN_APPLICATIONS: {
    name: 'Application name',
    appNumber: 'Application number',
    primaryFunctionalCategoryTitle: 'Primary functional category',
    primaryOutcomeTitle: 'Primary outcome',
    withdrawnDate: 'Withdrawn date',
    actions: 'Actions',
  },
  FOOTER: {
    navHeader: 'Contact us',
    subHeading1: 'Grant advisor',
    subHeading1Phone: 'Toll free: 310-0000 then 780-422-7125',
    subHeading1Email: 'MA.LGFFCapital@gov.ab.ca',
    subHeading2: 'System support',
    subHeading2Phone: 'Toll free: 310-0000 then 780-644-2413',
    subHeading2Email: 'MA.LGFFAccess@gov.ab.ca',
  },
  RESOURCES: {
    resourcesHeading: 'Helpful links and information',
    lgffWebsite: 'LGFF website',
    lgffWebsiteLink: 'https://www.alberta.ca/local-government-fiscal-framework-capital-funding',
    lgffGuidelines: 'LGFF guidelines',
    lgffGuidelinesLink: 'https://open.alberta.ca/publications/local-government-fiscal-framework-program-guidelines',
    msiOperating: 'MSI Operating',
    msiOperatingLink: 'https://www.maconnect.alberta.ca/MAConnect/login',
    moreInformationText: 'Use your Alberta.ca Account for Organizations (formerly MADI-B) to login.',
    portalUserGuideLink: 'https://www.alberta.ca/system/files/ma-msi-operating-portal-user-guide.pdf',
    organizationSignUpGuideLink:
      'https://www.alberta.ca/system/files/ti-alberta-ca-account-for-organizations-reference-guide.pdf',
  },
  ALLOCATIONS_PAYMENTS: {
    title: 'Your allocations and payments',
    searchPlaceHolder: 'Search allocations and payments',
    programYear: 'Program year',
    totalAllocation: 'Total allocation',
    paid: 'Paid',
    paymentStatusLabel: 'Payment status',
    paidAtString: 'Payment date',
    isPaymentCriteriaMet: 'Payment criteria met',
    true: 'Yes',
    false: 'No',
    totalAlloc: 'Total of all allocations',
    totalPaid: 'Total paid',
  },
  SEPO: {
    btnDeleteSepo: 'Delete all draft SEPO data',
    editSepoCalloutHeading: 'To complete your SEPO, follow these 5 simple steps:',
    editSepoCalloutStep1: 'Provide your contact information',
    editSepoCalloutStep2: 'Update your available LGFF funding',
    editSepoCalloutStep3: 'Add the expenditures for your in progress and completed projects',
    editSepoCalloutStep4: 'State the outcomes for your completed projects',
    editSepoCalloutStep5: "Certify and submit your SEPO - and you're done",
    editSepoCalloutFinalLine: 'You can view all your SEPOs (including drafts) at any time.',
    editSepoTitle: 'Statement of Expenditures and Project Outcomes',
    editSepoDeadlineText:
      'Local Governments are required to submit an annual report as outlined in the Memorandum of Agreement for the LGFF program. The due date for submitting Annual Report to Alberta Municipal Affairs is May 1 for all local governments except for Metis Settlements (August 1).',
    contactInfo: 'Contact information',
    assetFund: 'Asset management and funds',
    statementExpend: 'Statement of expenditures',
    immediateOutcomes: 'Immediate outcomes ',
    certifySubmit: 'Certify and submit',
    tableTitle: 'Your SEPOs',
    programYear: 'Program year',
    status: 'SEPO status',
    totalFundingAvailable: 'Total LGFF funding available',
    totalLgffExpenditures: 'Total LGFF expenditures',
    totalLgffFundingRemaining: 'Total LGFF funding remaining',
    actions: 'Actions',

    contactSectionHeading1: 'Contact information',
    contactSectionHeading2: 'Enter your SEPO contact information',
    deleteDraftDataSuccessSnack: 'Draft SEPO data successfully deleted.',

    assetFundHeading1: 'Asset management plan',
    assetFundHeading2: '2024 LGFF funding available',
    assetPlanQuestionLabel: 'Does your local government have an asset management plan?',
    assetPlanLastUpdatedlbl: 'When was the asset plan last updated?',
    assetPlanBarrierddllbl:
      'What are the primary barriers stopping your local government from developing and implementing an asset management plan?',
    assetFundCreditAmountError: 'Enter a value of 0 or greater.',
    assetFundLastUpdatedAtError: 'Select a date.',
    assetFundSelectBarrierError: 'Select a barrier.',
    assetFundBarrierOtherError: 'Specify the other primary barrier.',
    assetFundPlanYesNoError: 'Select if your local government has an asset management plan.',
    assetPlanCreditItemLabel: 'Credit items',
    assetFundCarryForwardLabel: 'LGFF funding carry-forward from previous years',
    assetPlanCreditAmountHelpText: 'Enter interest earned or the value of other credit items.',
    assetFundPlanQuestion1HelpText:
      'As asset management is not required for SEPO certification and/or to meet payment conditions. Your response will help the province better understand the current state of asset management across local governments and identify where additional resources, guidance, or support may be needed.',
  },
  REPORTS: {
    paymentReportTitle: 'Payment reports',
    invoiceSummaryReport: 'Invoice summary report',
    paymentStatusReport: 'Payment status reports',
    lgffCapital: 'Local Government Fiscal Framework - Capital',
    reportYear: 'Report year',
  },
  APPLICATION_FUNDING: {
    otherSourceOfFundingErrorMsg: 'Select if there are other sources of provincial or federal grant funding.',
    noSourceSelectedErrorMsg: 'Select the other sources of provincial or federal grant funding.',
    otherNoSpecifiedErrorMsg: 'Specify the source of the provincial or federal grant funding.',
    reasonForUpdateNull: 'Enter a reason for the update.',
    areThereOtherSourcesErrorMsg: 'Are there other sources of provincial or federal grant funding?',
  },
  CASH_FLOW_UPDATE_REVIEW: {
    title: 'Review',
    subTitle: 'Confirm that the changes below are correct.',
    projectWithdrawnTitle: 'Withdraw project',
    anticipatedStartDateTitle: 'Anticipated start date',
    quantityNewTitle: 'Quantity: new',
    quantityUpgradeTitle: 'Quantity: upgrade',
    anticipatedEndDateTitle: 'Anticipated end date',
    estimatedTotalCostTitle: 'Estimated total cost',
    amountRequestedFromLGFFTitle: 'LGFF funding amount requested',
    amountFromOtherGrantProgramsTitle: 'Funding from other grant programs',
    totalAmountFromMunicipalSourcesTitle: 'Funding from municipal sources',
  },
  CASH_FLOW_PROJECT_UPDATES: {
    projectName: 'Project name',
    projectNumber: 'Project number',
    primaryCapitalAsset: 'Primary capital asset',
    additionalCapitalAsset: 'Additional capital asset',
    previousAcceptedLGFFfunding: 'Previous accepted LGFF funding',
    previousStartDate: 'Previous accepted anticipated start date',
    expandCol: '',
  },
  CASH_FLOW_APPLICATIONS: {
    status: 'Status',
    cashFlowUpdateId: 'Cash flow update ID',
    applicationName: 'Application name',
    applicationNumber: 'Application number',
    previousAcceptedFunding: 'Previous accepted total LGFF funding',
    updatedTotalFunding: 'Updated total LGFF funding',
    actions: 'Actions',
    areYouSureCashFlowUpdateDelete: "Are you sure you'd like to delete cash flow update %%?",
    completeCashFlowUpdateDeleteLabel: 'Delete cash flow update',
    yesCashFlowUpdateDelete: 'Yes, delete update',
    noCancel: 'No, cancel',
  },
  USER_MANAGEMENT: {
    newUserBtnText: 'New User',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email address',
    firstNameErrorMsg: 'Enter a first name.',
    lastNameErrorMsg: 'Enter a last name.',
    emailErrorMsg: 'Enter a email address.',
  },
};
