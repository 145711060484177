import { Injectable } from '@angular/core';
import { EditSepoSequence } from '@app-pot/features/sepo/edit-sepo/enum/edit-sepo-sequence.enum';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ResetSEPOState,
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetEditSepoStepperStatus,
  SetSEPOContact,
  SetSEPOApplication,
  SetSEPOAssetManagementPlan,
  AutosaveSEPOAssetManagementPlan,
} from '../actions/edit-sepo.action';
import { SepoExtDto } from '@app-com/api/models';
import { AutoSaveState } from './application-draft.state';
import { AutosaveStatus, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { SepoExtService } from '@app-com/api/services';
import { CurrentContextState } from './current-context.state';

export class EditSepoStateModal {
  editSepoStepperState: {
    [EditSepoSequence.ContactInfo]: FormStatusCodes;
    [EditSepoSequence.AssetManagementFunds]: FormStatusCodes;
    [EditSepoSequence.StatementExpenditures]: FormStatusCodes;
    [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes;
    [EditSepoSequence.Certification]: FormStatusCodes;
  };

  sepo: Partial<SepoExtDto>;
  autosaveStatus: AutoSaveState;
  editSepoCurrentStep: EditSepoSequence;
  buttonAction: ['cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'] | undefined;
}

@State<EditSepoStateModal>({
  name: 'editSEPO',
  defaults: {
    editSepoStepperState: {
      [EditSepoSequence.ContactInfo]: FormStatusCodes.NotStarted,
      [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
      [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
      [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
      [EditSepoSequence.Certification]: FormStatusCodes.NotStarted,
    },
    sepo: {},
    autosaveStatus: { callsInProgress: 0, status: AutosaveStatus.idle },
    editSepoCurrentStep: EditSepoSequence.ContactInfo,
    buttonAction: undefined,
  },
})
@Injectable()
export class EditSepoState {
  currentOrganizationId: number;
  currentSEPOId: number | undefined;
  constructor(
    private sepoService: SepoExtService,
    private store: Store,
  ) {
    this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
      this.currentOrganizationId = currentOrganizationId;
    });

    this.store.select(CurrentContextState.getCurrentSEPOId).subscribe((currentSEPOId) => {
      this.currentSEPOId = currentSEPOId;
    });
  }
  @Selector()
  static getEditSepoStepperStatus(state: EditSepoStateModal) {
    return state.editSepoStepperState;
  }

  @Selector()
  static getEditSepoCurrentStep({ editSepoCurrentStep }: EditSepoStateModal) {
    return editSepoCurrentStep;
  }

  @Selector()
  static getEditSepoButtonAction({ buttonAction }: EditSepoStateModal) {
    return buttonAction;
  }

  @Selector()
  static getSepoDetails({ sepo }: EditSepoStateModal) {
    return sepo;
  }

  @Action(SetEditSepoStepperStatus)
  setEditSepoStepperStatus(
    { getState, setState }: StateContext<EditSepoStateModal>,
    { editSepoStepperState }: SetEditSepoStepperStatus,
  ) {
    console.debug('Edit SEPO Stepper Status [State]: ', editSepoStepperState);
    setState(
      patch({
        editSepoStepperState: {
          ...getState().editSepoStepperState,
          ...editSepoStepperState,
        },
      }),
    );
  }

  @Action(SetEditSepoCurrentStep)
  setEditSepoCurrentStep(
    { setState }: StateContext<EditSepoStateModal>,
    { editSepoCurrentStep }: SetEditSepoCurrentStep,
  ) {
    console.debug('Edit SEPO Step [State]: ', editSepoCurrentStep);
    setState(patch({ editSepoCurrentStep: editSepoCurrentStep }));
  }

  @Action(SetEditSepoButtonAction)
  setEditSepoButtonAction({ setState }: StateContext<EditSepoStateModal>, { buttonAction }: SetEditSepoButtonAction) {
    console.debug('Edit SEPO Button Action [State]: ', buttonAction);
    const newButtonAction: ['cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'] = [
      buttonAction ? buttonAction[0] : 'next',
    ];
    setState(patch({ buttonAction: [...newButtonAction] }));
  }

  @Action(SetSEPOContact)
  setSEPOContact(ctx: StateContext<EditSepoStateModal>, { payload }: SetSEPOContact) {
    ctx.setState(patch({ sepo: { ...ctx.getState().sepo, ...payload } }));
    this._autosave(ctx, false);
    console.log('Current State after change in Cash Flow Form Contact', ctx.getState());
  }

  @Action(SetSEPOAssetManagementPlan)
  setSEPOAssetManagementPlan(
    { getState, setState }: StateContext<EditSepoStateModal>,
    { payload }: SetSEPOAssetManagementPlan,
  ) {
    setState(patch({ sepo: { ...getState().sepo, ...payload } }));
  }

  @Action(SetSEPOApplication)
  setSEPOApplication({ getState, setState }: StateContext<EditSepoStateModal>, { payload }: SetSEPOApplication) {
    setState(patch({ sepo: { ...getState().sepo, ...payload } }));
  }

  @Action(AutosaveSEPOAssetManagementPlan)
  autosaveSEPOAssetManagementPlan(ctx: StateContext<EditSepoStateModal>, { payload }: AutosaveSEPOAssetManagementPlan) {
    ctx.setState(patch({ sepo: { ...ctx.getState().sepo, ...payload } }));
    this._autosave(ctx);
  }

  @Action(ResetSEPOState)
  resetSepoState({ setState }: StateContext<EditSepoStateModal>) {
    setState(
      patch({
        editSepoStepperState: {
          [EditSepoSequence.ContactInfo]: FormStatusCodes.NotStarted,
          [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
          [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
          [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
          [EditSepoSequence.Certification]: FormStatusCodes.NotStarted,
        },
        sepo: {},
        autosaveStatus: { callsInProgress: 0, status: AutosaveStatus.idle },
        editSepoCurrentStep: EditSepoSequence.ContactInfo,
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _autosave({ getState, setState, dispatch }: StateContext<EditSepoStateModal>, autosave: boolean = true) {
    //Get shared state from Current Context store
    const currentOrganizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    const currentSepoId = this.store.selectSnapshot(CurrentContextState.getCurrentSEPOId);

    if (autosave) {
      setState(
        patch({
          autosaveStatus: {
            callsInProgress: getState().autosaveStatus.callsInProgress + 1,
            status: AutosaveStatus.inProgress,
          },
        }),
      );
    }

    if (getState().sepo && currentSepoId) {
      const { contactFirstName, contactLastName, contactEmailAddress, contactPhoneNumber } = getState().sepo;
      this.sepoService
        .update({
          id: currentSepoId?.toString(),
          organizationId: currentOrganizationId,
          body: {
            contactFirstName,
            contactLastName,
            contactEmailAddress,
            contactPhoneNumber,
            sepoProjectExpenditures: [],
            sepoProjectOutcomes: [],
          },
        })
        .subscribe({
          next: () => {
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.success : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
          },
          error: (err) => {
            console.error('Error occurred [state]', err);
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.failure : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
          },
        });
    }
  }
}
