<div>
  <h2 id="project-updates-heading-h2">Project updates</h2>
  <h3 id="project-updates-subheading">Click on the arrow and pencil icons to view or edit your projects.</h3>

  <goa-callout
    *ngIf="
      (cumulativeAmountFromOtherGrantPrograms !== undefined &&
        cumulativeAmountFromOtherGrantPrograms == 0 &&
        !editProjectPencilClicked) ||
      ((toggleAreThereOtherSourcesOfFunding$ | async) !== undefined &&
        (toggleAreThereOtherSourcesOfFunding$ | async) &&
        !editProjectPencilClicked)
    "
    type="important"
    heading="The following items on this page require attention:"
  >
    The 'Funding from other grant programs' inputs have been added to the projects. Please add a value for at least one
    project.
  </goa-callout>

  <goa-callout
    *ngIf="
      cumulativeAmountFromOtherGrantPrograms !== undefined &&
      cumulativeAmountFromOtherGrantPrograms == 0 &&
      (getErrorCallout$ | async) &&
      editProjectPencilClicked
    "
    type="emergency"
    heading="The following items on this page require attention:"
  >
    At least one project needs a value for 'Funding from other grant programs'
  </goa-callout>

  <goa-callout
    *ngIf="
      (toggleAreThereOtherSourcesOfFunding$ | async) !== undefined && !(toggleAreThereOtherSourcesOfFunding$ | async)
    "
    type="important"
    heading="The following items on this page require attention:"
  >
    The 'Funding from other grant programs' value(s) have been removed. Please review all project monetary values to
    ensure they're correct:
    <ng-container *ngFor="let project of projectsWithOtherFunding">
      <div>
        <a (click)="jumpToField(project.projectId)" class="action-icon">{{ project.projectName }}</a>
      </div>
    </ng-container>
  </goa-callout>

  <app-lgff-goa-table
    [tableId]="pageId"
    [resPageId]="pageId"
    [colDef]="colDef"
    [tableData]="tableData"
    [displayPaginator]="displayPaginator"
    [renderCollapsibleRow]="true"
    [showSummaryColumns]="showSummaryColumns"
    [summaryColDef]="summaryCol"
    [collapsibleTemplate]="collapsibleTemplate"
    [collapsibleRowSpan]="8"
  >
  </app-lgff-goa-table>

  <div class="scroll-to-top-gap" variant="relaxed">
    <goa-button leadingIcon="arrow-up" type="secondary" (click)="scrollToTop()">Back to top</goa-button>
  </div>

  <ng-template #expandCol let-element let-column="column" let-colDef="colDef">
    <goa-icon
      class="action-icon"
      [type]="element['detailsShown'] ? 'chevron-down' : 'chevron-forward'"
      (click)="toggleProjectDetails(element[column])"
      id="{{ element[column] }}"
    ></goa-icon>
  </ng-template>

  <ng-template #primaryCapitalAsset let-element let-column="column" let-colDef="colDef">
    {{ getCapitalAssetTypeTitle(element[column]) }}
  </ng-template>

  <ng-template #additionalCapitalAsset let-element let-column="column" let-colDef="colDef">
    {{ getAdditionalCapitalAssetTypeTitle(element[column]) }}
  </ng-template>

  <ng-template #previousAcceptedLGFFfundingTemplate let-element let-column="column" let-colDef="colDef">
    {{ element[column] | currency: '$' : 'symbol' : '1.0-0' }}
  </ng-template>

  <ng-template #actionsTemplate let-element let-column="column" let-colDef="colDef">
    <goa-icon
      class="action-icon"
      type="pencil"
      theme="outline"
      size="medium"
      (click)="onEditProject(element)"
    ></goa-icon>
  </ng-template>

  <ng-template #collapsibleTemplate let-projectUpdates>
    <goa-block id="{{ projectUpdates.projectId }}" class="expandable-container" direction="column">
      <div class="expandable-row" *ngIf="!projectUpdates?.isProjectWithdrawn">
        <div class="expandable-col">
          <span>Quantity: new</span>
          <span>{{ projectUpdates?.quantityNew_Updated || '' }}</span>
        </div>
        <div class="expandable-col">
          <span>Quantity: upgrade</span>
          <span>{{ projectUpdates?.quantityUpgrade_Updated || '' }}</span>
        </div>
        <div class="expandable-col">
          <span>Anticipated start date</span>
          <span>{{
            projectUpdates?.formattedAnticipatedStartDateUpdated ||
              projectUpdates?.formattedAnticipatedStartDatePrevious
          }}</span>
        </div>
        <div class="expandable-col">
          <span>Anticipated end date</span>
          <span>{{
            projectUpdates?.formattedAnticipatedEndDateUpdated || projectUpdates?.formattedAnticipatedEndDatePrevious
          }}</span>
        </div>
      </div>
      <div class="expandable-row amount-row" *ngIf="!projectUpdates?.isProjectWithdrawn">
        <div class="expandable-col">
          <span>Estimated total cost</span>
          <span class="numeric-column">{{ projectUpdates?.estimatedTotalCost_Updated ?? 0 | safeDollar }}</span>
        </div>
        <div class="expandable-col">
          <span>LGFF funding amount requested</span>
          <span class="numeric-column">{{ projectUpdates?.amountRequestedFromLGFF_Updated ?? 0 | safeDollar }}</span>
        </div>
        <div class="expandable-col">
          <span>Funding from other grant programs</span>
          <span class="numeric-column">{{
            projectUpdates?.amountFromOtherGrantPrograms_Updated ?? 0 | safeDollar
          }}</span>
        </div>
        <div class="expandable-col">
          <span>Funding from municipal sources</span>
          <span class="numeric-column">{{
            projectUpdates?.totalAmountFromMunicipalSources_Updated ?? 0 | safeDollar
          }}</span>
        </div>
      </div>
      <div class="expandable-row">
        <div class="expandable-col" *ngIf="projectUpdates?.isProjectWithdrawn">
          <span>Withdraw project</span>
          <span>{{ projectUpdates?.isProjectWithdrawn ? 'Yes' : 'No' }}</span>
        </div>
        <div class="expandable-col" *ngIf="projectUpdates?.reasonForUpdate?.length > 0">
          <span class="col-reason-for-update">Reason for update</span>
          <span class="col-reason-for-update">{{ projectUpdates?.reasonForUpdate }}</span>
        </div>
        <div class="expandable-col"></div>
        <div class="expandable-col"></div>
      </div>
    </goa-block>
  </ng-template>
</div>
<ng-container *ngIf="openCashFlowProjectUpdateModal">
  <app-lgff-goa-modal
    [id]="'cash-flow-certify-submit-confirmation-modal'"
    [pageId]="pageId"
    [headingId]="'Edit'"
    [open]="openCashFlowProjectUpdateModal"
    [actionBtnNumber]="2"
    [maxwidth]="'1024px'"
    [okBtnLabel]="'Save'"
    (okBtnClickedEvent)="closeCashFlowProjectEditModal()"
    (cancelBtnClickedEvent)="cancelCashFlowProjectEditModal()"
  >
    <app-cash-flow-project-updates-edit-form
      (isProjectValid)="isProjectValid($event)"
      [storedProjectToUpdate]="projectToUpdate"
      [areThereOtherSourcesOfFunding]="areThereOtherSourcesOfFunding"
    ></app-cash-flow-project-updates-edit-form>
  </app-lgff-goa-modal>
</ng-container>
